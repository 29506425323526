import { applications } from "@sciam/auth0";
import { useFlags } from "~core/hooks/use-flags";

const auth0Apps = Object.fromEntries(applications.map((app) => [app.slug, app]));

/**
 * @returns {typeof applications[number]}
 */
export function useAuth0Env() {
  const { auth0 } = useFlags() || {};
  return auth0Apps[auth0] || auth0Apps[import.meta.env.PUBLIC_AUTH0_APP] || auth0Apps.prod;
}

export default useAuth0Env;
